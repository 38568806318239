<template>
  <div class="">
    <div class="d-flex">
      <div class="w-30">
        <div class="mb-3">
          Актуальность промокодов
        </div>

        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="filters.isActual"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="true"
            @input="$emit('search')"
          >
            Только актуальные промокоды
          </b-form-radio>
          <b-form-radio
            v-model="filters.isActual"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="false"
            class="mt-2"
            @input="$emit('search')"
          >
            Все промокоды
          </b-form-radio>
        </b-form-group>
      </div>
      <div class="ml-5 w-100">
        <div class="mb-3 pl-2">
          Выбор партнёра (юр.лица)
        </div>
        <v-select
          v-model="filters.legalPartnerId"
          class="w-50 ml-2"
          :reduce="legal => legal.id"
          :options="formatedLegalPartnerList"
          placeholder="Выберите партнёра"
          label="fullName"
          @input="$emit('search')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LaboratoryPromocodesSearchFilters',

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    legalPartnerList: {
      type: Array,
      requreid: true,
    },
  },
  data: () => ({
  }),
  computed: {
    filters: {
      get() {
        return this.value;
      },
      set(filters) {
        this.$emit('input', filters);
      },
    },
    formatedLegalPartnerList() {
      return this.legalPartnerList.map((partner) => ({
        ...partner,
        fullName: `${partner.partner.name} (${partner.legalName})`,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
